import { TbFileSpreadsheet } from 'react-icons/tb';
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  DocumentIcon,
  EnvelopeIcon,
  MinusIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  VideoCameraIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Button } from '@tremor/react';

const PrimaryCTA = ({
  onClick,
  ctaText,
  disabled,
  icon,
  viewStyle = '',
  loading,
}: {
  onClick: () => void;
  ctaText: string;
  disabled?: boolean;
  icon?:
    | 'plus'
    | 'minus'
    | 'edit'
    | 'confirm'
    | 'deny'
    | 'refresh'
    | 'export'
    | 'send'
    | 'delete'
    | 'video'
    | 'document'
    | 'email'
    | 'link';
  viewStyle?: string; //container style
  loading?: boolean;
}) => {
  const selectIcon = (iconType: string) => {
    switch (iconType) {
      case 'plus':
        return PlusIcon;
      case 'minus':
        return MinusIcon;
      case 'export':
        return TbFileSpreadsheet;
      case 'edit':
        return PencilSquareIcon;
      case 'confirm':
        return CheckCircleIcon;
      case 'deny':
        return XCircleIcon;
      case 'send':
        return PaperAirplaneIcon;
      case 'delete':
        return TrashIcon;
      case 'video':
        return VideoCameraIcon;
      case 'document':
        return DocumentIcon;
      case 'refresh':
        return ArrowPathIcon;
      case 'email':
        return EnvelopeIcon;
      case 'link':
        return ArrowTopRightOnSquareIcon;
    }
  };

  return (
    <div className={viewStyle}>
      <Button
        variant="primary"
        className="bg-primaryColor z-0 border-primaryColor hover:bg-primaryColor/[0.2] hover:border-primaryColor/[0.2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryColor"
        loading={loading}
        disabled={disabled}
        iconPosition="right"
        icon={icon && selectIcon(icon)}
        onClick={onClick}>
        {ctaText}
      </Button>
    </div>
  );
};

export default PrimaryCTA;
